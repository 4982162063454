<template>
    <shopping-cart-component/>
</template>
<script>
import ShoppingCartComponent from "@/esf_weert_mobilesupplies/core/components/partial/shooping-cart/ShoppingCartComponent.vue";
import config from "@config";

export default {
    components: {
        ShoppingCartComponent
    },
    metaInfo() {
        return {
            title: this.$t("shopping_cart"),
            link: [
                {
                    rel: "canonical",
                    href:
                        config.app_url +
                        (/\/$/.test(this.$route.fullPath)
                            ? this.$route.fullPath
                            : this.$route.fullPath.replace(/^\//, "") + "/"),
                },
            ],
        };
    },
};
</script>